<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>
        <el-form ref="form" :model="formData" :rules="rules" label-width="100px">

            <el-form-item prop="member_id" label="会员等级" label-width="100px">
                <el-select v-model="formData.user_member.member_id" placeholder="请选择">
                    <el-option v-for="item in memberList" :key="item.id" :label="item.title"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="download_member_type" label="下载会员等级" label-width="100px">
                <el-select v-model="formData.user_member.download_member_type" placeholder="请选择">
                    <el-option v-for="item in downloadMemberList" :key="item.id" :label="item.title"
                               :value="item.id"></el-option>
           </el-select>
            </el-form-item>
              <el-form-item v-if="(formData.user_member.download_member_type || 0) == 1" prop="downloads" label="下载次数">
                  <el-input-number v-model="formData.user_member.downloads" :min="0" :max="99999999" label="下载次数"></el-input-number>
            </el-form-item>
              <el-form-item v-if="(formData.user_member.download_member_type || 0) != 0" prop="start_time" label="会员开始日期">
              <el-date-picker
              v-model="formData.user_member.start_time"
              type="date"
              placeholder="选择会员开始日期">
            </el-date-picker>
            </el-form-item>
              <el-form-item v-if="(formData.user_member.download_member_type || 0) != 0" prop="end_time" label="会员结束日期">
             <el-date-picker
              v-model="formData.user_member.end_time"
              type="date"
              placeholder="会员结束日期">
            </el-date-picker>
            </el-form-item>
            <el-form-item prop="account" label="账号">
                <el-input size="small" v-model="formData.account" placeholder="账号"></el-input>
            </el-form-item>

            <el-form-item prop="password" label="密码">
                <el-input size="small" placeholder="请输入密码" v-model="formData.password" show-password></el-input>
            </el-form-item>

            <el-form-item prop="mobile" label="手机号码">
                <el-input size="small" v-model="formData.mobile" placeholder="手机号码"></el-input>
            </el-form-item>

<!--            <el-form-item label="封面图" prop="cover">-->
<!--                <UploaderImg :url.sync="formData.face"></UploaderImg>-->
<!--            </el-form-item>-->
            <el-form-item label=" ">
                <el-button size="small" type="" @click="cancel">取消</el-button>
                <el-button size="small" type="primary" @click="save">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {detail, editUser, addUserApi, getAllMember} from './api'
import {DICT_SYSTEM_SEX, DICT_SYSTEM_STATUS} from '@/enum/dict'

export default {
    name: 'AddOrEdit',
    data() {
        return {
            options: [],
            DICT_SYSTEM_SEX,
            DICT_SYSTEM_STATUS,
            memberList: [
                {
                    id: 0,
                    title: '请选择会员等级'
                }
          ],
          downloadMemberList: [
                {
                    id: 0,
                    title: '游客'
          },
          {
                    id: 1,
                    title: '普通会员'
          },
                {
                    id: 2,
                    title: '年度会员'
                }
            ],
            formData: {
                face: '',
                account: '',
                password: '',
                realname: '',
                mobile: '',
                from: '',
                user_member: {
                    member_id: 0,
                    download_member_type: 0,
                    downloads: 0,
                    start_time: "",
                    end_time: 0,
                }
            },
            rules: {
                // realname: [
                //   {
                //     required: true,
                //     message: '真实姓名',
                //     trigger: 'blur'
                //   }
                // ]
            }
        }
    },
    mounted() {
        if (this.$route.params.id) this.getDetail()
        this.getAllMember()
    },

    methods: {
        updateSex(val) {
            this.formData.sex = val
        },
        updateStatus(val) {
            this.formData.status = val
        },
        getUploadUrl(imageUrl) {
            this.formData.cover = imageUrl
        },
        save() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    if (this.formData.id) {
                        // 编辑
                        await editUser(this.formData).then(() => {
                            this.$message.success('编辑成功')
                            this.$router.back()
                        })
                    } else {
                        // 新增
                        await addUserApi(this.formData).then(() => {
                            this.$message.success('新增成功')
                            this.$router.back()
                        })
                    }
                } else {
                    return false
                }
            })
        },
        async getDetail() {
            this.formData = await detail(this.$route.params.id)
          
            console.log(this.formData, "3333")
        },
        async getAllMember() {
          const result = await getAllMember({})
          console.log(result, "222222222")
          
          this.memberList =  this.memberList.concat(result)
        },
        cancel() {
            this.$router.back(-1)
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;

        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
