import { http } from '@/http/axios.js'
// 列表
export function getList(params) {
  return http({
    url: '/company/user/list',
    method: 'get',
    params
  })
}
// 详情
export function detail(id) {
  return http({
    url: '/company/user/detail',
    method: 'get',
    params: { id: id }
  })
}

export function editUser(data) {
  return http({
    url: '/company/user/edit',
    method: 'post',
    data
  })
}

export function addUserApi(data) {
  return http({
    url: '/company/user/add',
    method: 'post',
    data
  })
}

export function getAllMember(params) {
  return http({
    url: '/company/member/getAll',
    method: 'get',
    params
  })
}
export function delAPI(id) {
  return http({
    url: '/company/user/del',
    method: 'post',
    data: { id }
  })
}
